var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no_result" }, [
    _c("div", { staticClass: "center_box" }, [
      _c("i", {
        class: ["icon-pub", "iconfont", _vm.iconUrl],
        style: _vm.iconSty
      }),
      _vm.showImg
        ? _c("img", {
            attrs: { src: require("../assets/images/no_video.png"), alt: "" }
          })
        : _vm._e(),
      _c("div", { staticClass: "text_sty", style: _vm.textSty }, [
        _vm._v(_vm._s(_vm.noResultText))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }