var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work_library materials common_page" },
    [
      _c("el-header", { attrs: { height: "52px" } }, [
        _c("div", { staticClass: "m_head head" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("智能化多媒体管理和生产系统")
          ])
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "contentWarp",
          staticClass: "list-warp mobile_warp",
          attrs: {
            "element-loading-background": "rgba(0, 0, 0, 0.1)",
            "element-loading-text": "素材加载中..."
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("共" + _vm._s(_vm.dataTotal) + "个素材")])
          ]),
          !_vm.listData.length && !_vm.listLoading
            ? _c("noResult", {
                staticStyle: { height: "55vh" },
                attrs: {
                  iconUrl: "icon-meiyousucai",
                  iconSty: "font-size:80px",
                  textSty: "font-size:18px",
                  noResultText: "暂无相关素材"
                }
              })
            : [
                _c(
                  "div",
                  { staticClass: "material_list" },
                  _vm._l(_vm.listData, function(item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "material_item" },
                      [
                        _c("div", { staticClass: "item_top" }, [
                          _c(
                            "div",
                            {
                              staticClass: "videoSize",
                              on: {
                                click: function($event) {
                                  return _vm.videoClick(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.showImg,
                                      expression: "item.showImg"
                                    }
                                  ],
                                  staticClass: "play_video"
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-bofang1"
                                  })
                                ]
                              ),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.showImg,
                                    expression: "item.showImg"
                                  }
                                ],
                                attrs: { src: item.cover, alt: "" }
                              }),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.showImg,
                                    expression: "!item.showImg"
                                  }
                                ],
                                style: item.showImg ? "width:0;height:0" : "",
                                attrs: { id: "video" + item.id }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btns",
                              style:
                                item.progress != 0 &&
                                item.progress <= 0.99 &&
                                item.progress != "-1"
                                  ? "display:block;"
                                  : ""
                            },
                            [
                              _c("i", {
                                staticClass: "ico_sty iconfont icon-fabu",
                                on: {
                                  click: function($event) {
                                    return _vm.getDycode(item.id, "mt")
                                  }
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "video_duration" }, [
                            _vm._v(_vm._s(item.duration))
                          ])
                        ]),
                        _c("div", { staticClass: "material_title" }, [
                          item.download_if
                            ? _c("span", [_vm._v("已分享")])
                            : _vm._e(),
                          _vm._v(_vm._s(item.works_name) + " ")
                        ])
                      ]
                    )
                  }),
                  0
                )
              ],
          !_vm.listLoading
            ? _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.page,
                  layout: "prev, pager, next",
                  "page-size": _vm.page_num,
                  total: _vm.dataTotal,
                  "hide-on-single-page": true,
                  "pager-count": 3
                },
                on: { "current-change": _vm.getData }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }